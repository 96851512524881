import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCodeModal from './components/QRCode';
import ImageCropper from './components/ImageCropper';
import { useMediaQuery } from 'react-responsive';

function App() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profileImage: null,
    phoneNumber: '',
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [originalImageData, setOriginalImageData] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [errors, setErrors] = useState({});
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required.';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required.';
    if (!formData.email.trim()) newErrors.email = 'Email is required.';
    if (!formData.phoneNumber.trim()) newErrors.phoneNumber = 'Phone number is required.';
    if (!formData.profileImage) newErrors.profileImage = 'Profile image is required.';
    return newErrors;
  };

  // Loading modal component
  const LoadingModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <video 
          autoPlay 
          loop 
          muted 
          className="w-full h-auto sm:w-64 sm:h-64" // Tailwind classes for responsive width and height
          style={{ maxWidth: '250px', maxHeight: '250px' }} // Inline styles to limit the size on larger screens
        >
          <source src={`${process.env.PUBLIC_URL}/loading.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file);
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log('Reader loaded:', e.target.result);
        setOriginalImageData(e.target.result); // Save the original image data
        setIsCropperOpen(true); // Open the cropper modal
        console.log('Cropper should be open now.');
  
        // Update formData.profileImage here, after you have confirmed the file is ready
        setFormData(prevFormData => ({
          ...prevFormData,
          profileImage: file // Set the file as profileImage in formData
        }));
  
        // Also clear any existing error for the profileImage
        setErrors(prevErrors => ({ ...prevErrors, profileImage: '' }));
      };
      reader.readAsDataURL(file);
    } else {
      setErrors(prevErrors => ({ ...prevErrors, profileImage: 'Profile image is required.' }));
    }
  };
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formErrors = validateFields();
  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
    setIsLoading(false);
    return; // Stop the function if there are errors
  }

    // Clear errors if all fields are validated
    setErrors({});
    
    //console.log('handleSubmit called'); // Log when the function is called
  
    const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
  
    const imageData = new FormData();
    imageData.append('profileImage', formData.profileImage);
  
    const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    console.log('Base URL:', baseURL); // Log the baseURL value
  
    const imageUploadUrl = `${baseURL}/upload`;
    const issueWalletUrl = `${baseURL}/issue-wallet`;
  
    try {
      console.log('Attempting to upload image'); // Before image upload attempt
      
      const imageResponse = await axios.post(imageUploadUrl, imageData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded successfully'); // After image upload attempt
  
      const profileImageUrl = imageResponse.data.url;
      console.log('Profile Image URL:', profileImageUrl); // Log the profile image URL
  
      const issueWalletData = {
        brandId: 20,
        programId: 54,
        templateId: 45,
        person: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
        },
        passdata: {
          profileImage: profileImageUrl,
        },
      };
  
      console.log('Issuing wallet with data:', issueWalletData); // Log data sent to issue wallet
      const walletResponse = await axios.post(issueWalletUrl, issueWalletData);
      console.log('Issuing wallet with URL:', issueWalletUrl)
      console.log('Wallet issued, response:', walletResponse.data); // Log the response from issuing the wallet
  
      if (walletResponse.data && walletResponse.data.downloadUrl) {
        const fullDownloadUrl = `https://wallet-pass.bambumeta.software${walletResponse.data.downloadUrl}`;
        
        // Check if the device is mobile
        if (isMobile) {
          // If mobile, open the full URL
          window.location.href = fullDownloadUrl;
        } else {
          // If desktop, show the QR modal
          setQrCodeUrl(fullDownloadUrl);
          setShowModal(true);
        }
  
        console.log('Wallet issued successfully:', walletResponse.data);
      } else {
        console.error('Wallet issued but download URL not found in response');
      }

    } catch (error) {
      console.error('Error occurred during form submission:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      // Add more detailed logging to see the actual error response from the server if available
    }
  
    setIsLoading(false);
  };
  
  
  return (
      <div className="flex flex-col min-h-screen bg-gray-100 bg-custom-pattern">
        {/* Navbar starts */}
        <nav className="bg-white shadow-lg">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex justify-between">
              <div className="flex space-x-7">
              <div>
                {/* Website Logo */}
                <a href="#" className="flex items-center py-4 px-2">
                  {/* Assuming your logo is named 'logo.png' and is placed in the public folder */}
                  <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo"className="h-16" />
                  {/* You can adjust the height (h-8) as needed */}
                </a>
              </div>
                {/* Primary Navbar items */}
                <div className="hidden md:flex items-center space-x-1">
                <a href="https://www.intrepidbenefits.com/" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Home</a>
                  <a href="https://www.intrepidbenefits.com/our-solutions" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Solutions</a>
                  <a href="#" className="py-4 px-2 text-green-500 border-b-4 border-green-500 font-semibold ">Wallet Cards</a>
                  <a href="https://www.intrepidbenefits.com/contact" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Contact Us</a>
                </div>
              </div>
              {/* Mobile menu button */}
              <div className="md:hidden flex items-center">
                <button className="outline-none mobile-menu-button" onClick={toggleMenu}>
                  <svg
                    className="w-6 h-6 text-gray-500 hover:text-green-500"
                    x-show="!showMenu"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16m-7 6h7"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* Mobile menu */}
          <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <a href="https://www.intrepidbenefits.com/" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300 ">Home</a>
                  <a href="https://www.intrepidbenefits.com/our-solutions" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Solutions</a>
                  <a href="#" className="py-4 px-2 text-green-500 border-b-4 border-green-500 font-semibold">Wallet Cards</a>
                  <a href="https://www.intrepidbenefits.com/contact" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Contact Us</a>
          </div>
        </nav>
        {/* Navbar ends */}
  
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center bg-custom-pattern">
      <div className="max-w-md w-full mx-auto">
        <h1 className="text-center text-4xl font-bold text-black-500">Get your wallet card</h1>
      </div>
      <div className="max-w-md w-full mx-auto mt-4 bg-white p-8 border border-gray-300">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="firstName" className="text-sm font-bold text-gray-600 block">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="John"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
             {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
          </div>
          <div>
            <label htmlFor="lastName" className="text-sm font-bold text-gray-600 block">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Doe"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
             {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
          </div>
          <div>
            <label htmlFor="email" className="text-sm font-bold text-gray-600 block">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="john.doe@example.com"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
             {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="phoneNumber" className="text-sm font-bold text-gray-600 block">Phone number</label>
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              pattern="^\+?([0-9]{1,3})?[-. (]?([0-9]{1,4})?[-. )]?([0-9]{1,4})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,9})?$"
              onChange={handleInputChange}
              title="Phone number must be in a valid format, e.g., +12345678900 or 123-456-7890"
              placeholder="e.g., +12345678900 or 123-456-7890"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
              />
              {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
          </div>
          <div>
            <label htmlFor="profileImage" className="text-sm font-bold text-gray-600 block">Profile Image</label>
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
            {errors.profileImage && <p className="text-red-500 text-xs mt-1">{errors.profileImage}</p>}

          </div>

          <div>
            <button type="submit" className="w-full p-2 bg-customBlue text-white rounded hover:bg-blue-600">Submit</button>
          </div>
        </form>
      </div>
      <QRCodeModal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      url={qrCodeUrl}
    />
      {/* Loading modal */}
      {isLoading && <LoadingModal />}
    </div>
    </div>
  );
}

export default App;