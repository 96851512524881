// QRCodeModal.js
import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeModal = ({ isOpen, onClose, url }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex flex-col items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Download Wallet Pass</h3>
          <p className="text-sm text-gray-500 mt-2">
            Scan the QR code below to download your wallet pass.
          </p>
          {/* QR Code container centered */}
          <div className="flex justify-center w-full my-4">
            <QRCode value={url} size={256} level={"H"} includeMargin={true} />
          </div>
          <button
            id="ok-btn"
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
